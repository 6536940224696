<template>
	<div>
		<!-- 引入top组件 -->
		<top :windowWidth="windowWidth" :windowHeight="windowHeight"></top>

		<!-- 引入子组件top组件 -->
		<subpage-top :title="$t('news.title2')" :breadcrumbText="$t('news.title2')" :image="image"
			:list="$t('news.topList2')" @anchorPoint="">
		</subpage-top>

		<div class="newsDetails">
			<div class="nd_tilte">{{detalis.articleTitle}}</div>
			<div class="nd_time">{{$t('news.text1')}}：{{detalis.createTime}}</div>

			<div class="nd_image" v-html="detalis.articleContent"></div>
			<el-image :src="detalis.Image" :fit="'cover'"></el-image>
		</div>

		<!-- 引入底部组件 -->
		<div v-if="windowWidth>=1200">
			<bottom></bottom>
		</div>

		<right></right>
	</div>
</template>

<script>
	import top from '@/components/top/top.vue'; //引入top组件
	import subpageTop from '@/components/subpageTop/index.vue'; //引入子组件top组件
	import bottom from '@/components/bottom/index.vue'; //引入bottom组件
	import right from '@/components/right/right.vue'; //引入bottom组件
	import {dataInJs} from './components/data.js'
	import {
		changeKeywords,
		changeDescription,
		changeTitle
	} from '@/utils/brower.js'
	export default {
		components: {
			top,
			bottom,
			subpageTop,
			right
		},
		data() {
			return {
				detalis: null,
				topList: [{
					id: 1,
					name: '新闻详情'
				}],
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
				image: require('@/assets/img/newsbg.jpg'),
			}
		},

		created() {
			this.detalis = this.$route.query
			if (this.$i18n.locale == 'cn') {
				// document.title = '芯航国际-新闻中心'
				changeTitle('深圳市芯航国际电子有限公司 官网-成为中国最值得信赖的IC元器件科技贸易服务商 芯片代理_ic代理 _芯片配单_电子元器件_半导体_军工')
				changeKeywords('芯片代理,ic代理,芯片配单,电子元器件,半导体,军工,芯航国际,IC,元器件,芯片,芯片贸易,芯片采购,芯片搜索')
				changeDescription('芯片代理,ic代理 ,芯片配单,电子元器件,半导体,军工,深圳市芯航国际电子有限公司专业代理分销ADI,TI,ALTERA,XILINX等国际知名半导体产品，业务涉及CPLD/FPGA各种中高低端及现场可编程逻辑技术、高效的DSP数字信号及ARM处理器、,FLASH存储及智能电源管理技术等领域。公司业务主要覆盖汽车电子,工业控制,电力电子,通讯网络,航空航天,物联网,医疗几大行业。')
			} else {
				// document.title = 'xxintek-News'
				changeTitle('Shenzhen Xxintek International Electronics Co., Ltd. Official Website - Becoming the Most Trusted IC Component Technology Trade Service Provider Chip Agent in China_ IC Proxy_ Chip allocation_ Electronic components_ Semiconductor_ Military Industry')
				changeKeywords('Chip agent, IC agent, chip ordering, electronic components, semiconductors, military industry, Xxintek International, IC, components, chips, chip trade, chip procurement, chip search')
				changeDescription("Chip agent, IC agent, chip ordering, electronic components, semiconductors, military industry, Shenzhen Xxintek International Electronics Co., Ltd. specializes in the distribution of internationally renowned semiconductor products such as ADI, TI, Altera, XILINX, etc. Our business involves various fields such as CPLD/FPGA mid to low end and on-site programmable logic technology, efficient DSP digital signals and ARM processors, FLASH storage and intelligent power management technology. The company's business mainly covers several major industries, including automotive electronics, industrial control, power electronics, communication networks, aerospace, Internet of Things, and healthcare.")
			}
		},

		mounted() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
		},

		beforeCreate() {
			if (this.$i18n.locale == 'cn') {
				changeTitle('深圳市芯航国际电子有限公司 官网-成为中国最值得信赖的IC元器件科技贸易服务商 芯片代理_ic代理 _芯片配单_电子元器件_半导体_军工')
				changeKeywords('芯片代理,ic代理,芯片配单,电子元器件,半导体,军工,芯航国际,IC,元器件,芯片,芯片贸易,芯片采购,芯片搜索')
				changeDescription('芯片代理,ic代理 ,芯片配单,电子元器件,半导体,军工,深圳市芯航国际电子有限公司专业代理分销ADI,TI,ALTERA,XILINX等国际知名半导体产品，业务涉及CPLD/FPGA各种中高低端及现场可编程逻辑技术、高效的DSP数字信号及ARM处理器、,FLASH存储及智能电源管理技术等领域。公司业务主要覆盖汽车电子,工业控制,电力电子,通讯网络,航空航天,物联网,医疗几大行业。')
			} else {
				changeTitle('Shenzhen Xxintek International Electronics Co., Ltd. Official Website - Becoming the Most Trusted IC Component Technology Trade Service Provider Chip Agent in China_ IC Proxy_ Chip allocation_ Electronic components_ Semiconductor_ Military Industry')
				changeKeywords('Chip agent, IC agent, chip ordering, electronic components, semiconductors, military industry, Xxintek International, IC, components, chips, chip trade, chip procurement, chip search')
				changeDescription("Chip agent, IC agent, chip ordering, electronic components, semiconductors, military industry, Shenzhen Xxintek International Electronics Co., Ltd. specializes in the distribution of internationally renowned semiconductor products such as ADI, TI, Altera, XILINX, etc. Our business involves various fields such as CPLD/FPGA mid to low end and on-site programmable logic technology, efficient DSP digital signals and ARM processors, FLASH storage and intelligent power management technology. The company's business mainly covers several major industries, including automotive electronics, industrial control, power electronics, communication networks, aerospace, Internet of Things, and healthcare.")
			}
		},

		watch: {
			//双向绑定语言切换
			'$i18n.locale'(val) {
				if (this.$i18n.locale == 'cn') {
					// document.title = '芯航国际-新闻中心'
					var a = dataInJs('cn')
					var b = null
					a.map(res=>{
						if(res.id==this.detalis.id){
							b=res
						}
					})
					this.detalis=b
				} else {
					// document.title = 'xxintek-News'
					var a = dataInJs('en')
					var b = null
					a.map(res=>{
						if(res.id==this.detalis.id){
							b=res
						}
					})
					this.detalis=b
				}
			}
		},

		computed: {

		},

		methods: {

		}
	}
</script>

<style lang="less">
	@media only screen and (min-width:1920px) {
		.newsDetails {
			padding: 40px 400px;
			text-align: center;

			.nd_tilte {
				font-size: 28px;
				width: 100%;
				line-height: 3;
				border-bottom: 1px solid #eee;
			}

			.nd_time {
				font-size: 28px;
				line-height: 3;
			}

			.nd_image {
				padding-top: 20px;
				white-space:normal;  //规定段落中的文本不进行换行
				word-break:break-all; //允许单词中换行，在容器的最右边进行断开不会浪费空间
				word-wrap:break-word; //防止长单词溢出，单词内部断句
				word-break:break-word; //如果不想单词内断行可以进行单词间的断行
			}
		}
	}

	@media only screen and (max-width:1920px) {
		.newsDetails {
			padding: 40px 400px;
			text-align: center;

			.nd_tilte {
				font-size: 28px;
				width: 100%;
				line-height: 3;
				border-bottom: 1px solid #eee;
			}

			.nd_time {
				font-size: 28px;
				line-height: 3;
			}

			.nd_image {
				padding-top: 20px;
			}
		}
	}

	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.newsDetails {
			padding: 40px 200px;
			text-align: center;

			.nd_tilte {
				font-size: 28px;
				width: 100%;
				line-height: 3;
				border-bottom: 1px solid #eee;
			}

			.nd_time {
				font-size: 28px;
				line-height: 3;
			}

			.nd_image {
				padding-top: 20px;
			}
		}
	}

	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.newsDetails {
			padding: 40px 60px;
			text-align: center;

			.nd_tilte {
				font-size: 28px;
				width: 100%;
				line-height: 3;
				border-bottom: 1px solid #eee;
			}

			.nd_time {
				font-size: 28px;
				line-height: 3;
			}

			.nd_image {
				padding-top: 20px;
			}
		}
	}

	@media only screen and (max-width:1199px) and (min-width:768px) {
		.newsDetails {
			padding: 40px;
			text-align: center;

			.nd_tilte {
				font-size: 28px;
				width: 100%;
				line-height: 3;
				border-bottom: 1px solid #eee;
			}

			.nd_time {
				font-size: 28px;
				line-height: 3;
			}

			.nd_image {
				padding-top: 20px;
			}
		}
	}

	@media only screen and (max-width:767px) {
		.newsDetails {
			padding: 20px;
			text-align: center;

			.nd_tilte {
				font-size: 28px;
				width: 100%;
				line-height: 3;
				border-bottom: 1px solid #eee;
			}

			.nd_time {
				font-size: 28px;
				line-height: 3;
			}

			.nd_image {
				padding-top: 20px;
			}
		}
	}
</style>
